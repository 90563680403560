var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.photoURL
    ? _c("img", {
        staticClass: "photo",
        attrs: { src: _vm.photoURL, alt: _vm.photoURL }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }