var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-view" },
    [
      _c(
        "a-tag",
        {
          staticClass: "template mb-xs",
          attrs: { color: _vm.colorMapping },
          on: { click: _vm.handleOpenIframeReview }
        },
        [_vm._v(" " + _vm._s(_vm.$t(_vm.template)) + " ")]
      ),
      _vm.publishBtnVisible && _vm.isPublishEditedVersion
        ? _c(
            "a-tooltip",
            {
              attrs: {
                title: _vm.data.ispublic
                  ? _vm.$t("There are changes that have not been updated.")
                  : _vm.$t("There are changes that have not been saved.")
              }
            },
            [
              _c(
                "a-button",
                {
                  staticClass: "mr-xs",
                  class: { blink: true, blinking: true },
                  attrs: {
                    size: "small",
                    loading: _vm.publish.loading,
                    type: "primary",
                    icon: "to-top"
                  },
                  on: {
                    click: function($event) {
                      _vm.publish.visible = true
                    }
                  }
                },
                [
                  _vm.data.ispublic
                    ? _c("span", [_vm._v(" " + _vm._s(_vm.$t("Update")) + " ")])
                    : _c("span", [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c("PublishWebsiteModal", {
        attrs: {
          visible: _vm.publish.visible,
          loading: _vm.publish.loading,
          page: _vm.data
        },
        on: {
          publish: _vm.publishPageSettingOptions,
          close: function($event) {
            _vm.publish.visible = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }