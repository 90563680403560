var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { visible: _vm.visible, footer: null },
      on: { cancel: _vm.handleCancelModal }
    },
    [
      _c(
        "span",
        {
          staticClass: "title text-black font-semi-bold",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _vm._v(
            _vm._s(
              _vm.$tc("Select destination project", _vm.actionType === "copy")
            )
          )
        ]
      ),
      _c(
        "a-radio-group",
        {
          model: {
            value: _vm.project,
            callback: function($$v) {
              _vm.project = $$v
            },
            expression: "project"
          }
        },
        _vm._l(_vm.filterProject, function(item) {
          return _c(
            "a-radio",
            { key: item.id, style: _vm.radioStyle, attrs: { value: item } },
            [_vm._v(" " + _vm._s(_vm._f("truncate")(item.name, 30)) + " ")]
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "content-end" },
        [
          _c(
            "a-button",
            { staticClass: "mr-sm", on: { click: _vm.handleCancelModal } },
            [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
          ),
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                disabled: !_vm.project,
                loading: _vm.loading
              },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v(" OK ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }