//
//
//
//

import Vue from 'vue';

export default Vue.extend({
  computed: {
    data() {
      return this.params.data || {};
    },
    photoURL() {
      return this.data?.thumbnail?.url;
    }
  }
});
