//
//
//
//
//
//

import { PAGE_STATE } from '@/enum';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    data() {
      return this.params?.data;
    },
    slug() {
      return this.data?.slug || this.data?.id;
    },
    path() {
      return this.data.state === PAGE_STATE.PRIMARY ? `/` : `/${this.slug}`;
    }
  }
});
