//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {},
  props: {
    visible: { type: Boolean, required: true },
    actionType: { type: String, default: '' },
    projects: { type: Array, default: () => [] }
  },
  data() {
    return {
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      project: {},
      loading: false
    };
  },
  computed: {
    filterProject() {
      return this.projects.filter(item => item.id != this.currentProjectId);
    },

    currentProjectId() {
      return this.$route.params?.projectId;
    }
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      new Promise((resolve, reject) => {
        let vm = this;
        this.$confirm({
          title: vm.$tc(
            'Do you want to jump to the copy(move) destination?',
            vm.actionType == 'copy'
          ),
          // content: 'When clicked the OK button, this dialog will be closed after 1 second',
          async onOk() {
            vm.$emit(vm.actionType, { selectedId: vm.project.id, resolve, reject });
            setTimeout(() => {
              vm.$router.push(`/projects/${vm.project.id}/pages`);
            }, 1000);
          },
          onCancel() {
            vm.$emit(vm.actionType, { selectedId: vm.project.id, resolve, reject });
          },
          cancelText: vm.$t('No'),
          okText: vm.$t('Yes')
        });
        this.loading = false;
      });
    },
    onSelect(project) {
      this.project = project;
    },
    handleCancelModal() {
      this.$emit('cancel');
    }
  }
};
