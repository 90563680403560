//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { PAGE_STATE } from '@/enum';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    name() {
      return this.params.data.name;
    },
    id() {
      return this.params.data.id;
    },
    isPublic() {
      return this.params.data.ispublic;
    },
    isPrimaryState() {
      return this.params?.data?.state === PAGE_STATE.PRIMARY;
    },
    isPinned() {
      return this.params.data.pinned;
    },
    isCatalog() {
      return this.params.data.template === 'CATALOG';
    }
  },
  methods: {}
});
