//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      visible: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters('project', ['projectById']),
    pageId() {
      return this.params.data.id;
    },
    isPublic() {
      return this.params.data.ispublic;
    }
  },

  methods: {}
});
