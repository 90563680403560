//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { PublishWebsiteModal } from '@/views/project/page/iframe-code-list/modals/index';

export default Vue.extend({
  components: {
    PublishWebsiteModal
  },
  data() {
    return {
      publish: {
        visible: false,
        loading: false
      }
    };
  },
  computed: {
    data() {
      return this.params.data || {};
    },
    configPassing() {
      const config = this.params.getValue();
      return config;
    },
    templateBtnClickable() {
      if (this.configPassing?.button?.template?.clickable === false) {
        return false;
      }
      return true;
    },
    publishBtnVisible() {
      if (this.configPassing?.button?.publish?.visible === false) {
        return false;
      }
      return true;
    },
    template() {
      return this.data?.template || 'CATALOG';
    },
    siteOpts() {
      return this.data?.project_site_opts;
    },
    draftSiteOpt() {
      return this.siteOpts?.find(item => item.status === 'DRAFT');
    },
    havePublishVersion() {
      return this.siteOpts?.find(item => item.status === 'PUBLISHED');
    },
    isPublishEditedVersion() {
      return this.draftSiteOpt?.is_draft_edited;
    },
    colorMapping() {
      const mapping = {
        CATALOG: 'blue',
        PORTAL: 'purple'
      };
      return mapping[this.template];
    }
  },
  methods: {
    handleOpenIframeReview() {
      if (!this.templateBtnClickable) return;
      this.params.context.componentParent.handleToggleIframeReviewFromChild(
        this.params.data.id,
        true
      );
    },
    async publishPageSettingOptions(input) {
      this.publish.loading = true;
      await this.params.context.componentParent.publishPageSettingOptions(
        this.data,
        this.draftSiteOpt.id,
        input
      );
      this.publish.visible = false;
      this.publish.loading = false;
    }
  }
});
