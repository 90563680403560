var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-switch", {
        attrs: {
          checked: _vm.isPublic,
          "checked-children": _vm.$t("Public"),
          "un-checked-children": _vm.$t("Private")
        },
        on: {
          change: function($event) {
            return _vm.params.context.componentParent.onOpenChangeStatusModelFromChild(
              _vm.params.data
            )
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }