//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-undef */
import { PAGE_STATE } from '@/enum';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ChooseProjectModal from '../modal/ChooseProjectModal';
export default Vue.extend({
  components: {
    ChooseProjectModal
  },
  data() {
    return {
      loading: false,
      duplicateModalVisible: false,
      isDuplicateCodes: false,
      isIncludeCodes: false,
      statusModalVisible: false,
      chooseProjectModalVisible: false,
      projectChoosingActionType: ''
    };
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('project', ['projectById', 'projects']),
    isPublic() {
      return this.params.data.ispublic;
    },
    isCatalogPage() {
      return this.params.data.template === 'CATALOG';
    },
    isPrimaryPage() {
      return this.params.data.state === PAGE_STATE.PRIMARY;
    },
    companyId() {
      return this.userInfo.company_id;
    },
    projectId() {
      return this.params.data.campaign_id;
    },
    pageId() {
      return this.params.data.id;
    },
    page() {
      return this.params.data;
    },
    pageTemplate() {
      return this.params.data.template;
    },
    pathToEdit() {
      return `/projects/${this.projectId}/pages/${this.pageId}`;
    },
    pathToCodes() {
      return `/projects/${this.projectId}/pages/${this.pageId}/codes`;
    },
    popupTitle() {
      return this.$t(
        'This page is private now, you need to change it to public to embed. Are you sure to change it to public?'
      );
    },
    currentProject() {
      return this.projectById(this.projectId) || {};
    },
    isChangeProjectStatusWarning() {
      return !this.currentProject.is_public && !this.isPublic;
    },
    isPinned() {
      return this.params.data.pinned;
    }
  },
  methods: {
    handleGoTo(path) {
      this.$router.push(path);
    },
    handleOpenIframeReview() {
      this.params.context.componentParent.handleToggleIframeReviewFromChild(this.pageId, true);
    },
    handlePinning() {
      this.params.context.componentParent.handlePinToTopFromChild(this.params.data);
    },
    async handlePageExport() {
      this.params.context.componentParent.handlePageExportFromChild(this.params.data);
    }
  }
});
