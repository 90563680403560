var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { type: "flex", align: "middle", justify: "space-around" } },
        [
          _c(
            "a-dropdown",
            [
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                [
                  _c(
                    "a-menu-item",
                    { key: "1", on: { click: _vm.handleOpenIframeReview } },
                    [
                      _c(
                        "span",
                        [
                          _c("a-icon", { attrs: { type: "code" } }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("project.grid.EditWebsite")) +
                              " "
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm.pageTemplate === "CATALOG"
                    ? _c(
                        "a-menu-item",
                        {
                          key: "2",
                          on: {
                            click: function($event) {
                              return _vm.handleGoTo(_vm.pathToCodes)
                            }
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "container" } }),
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("Code list")) + " ")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-menu-item",
                    {
                      key: "3",
                      on: {
                        click: function($event) {
                          return _vm.handleGoTo(_vm.pathToEdit)
                        }
                      }
                    },
                    [
                      _c("a-icon", { attrs: { type: "edit" } }),
                      _c("span", [_vm._v(" " + _vm._s(_vm.$t("Edit")) + " ")])
                    ],
                    1
                  ),
                  !_vm.isPrimaryPage
                    ? _c(
                        "a-menu-item",
                        { key: "setPrimary" },
                        [
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                placement: "top",
                                title: _vm.$t(
                                  "Are you sure to change the primary page?"
                                ),
                                "ok-text": _vm.$t("Yes"),
                                "cancel-text": _vm.$t("No")
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.params.context.componentParent.handleChangePrimaryPageFromChild(
                                    _vm.pageId
                                  )
                                }
                              }
                            },
                            [
                              _c("a-icon", { attrs: { type: "edit" } }),
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Set as primary")) + " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-menu-item",
                    {
                      key: "4",
                      on: {
                        click: function($event) {
                          return _vm.params.context.componentParent.onOpenDuplicatePageModalFromChild(
                            _vm.params.data
                          )
                        }
                      }
                    },
                    [
                      _c("a-icon", { attrs: { type: "copy" } }),
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("Duplicate")) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    {
                      key: "Copy",
                      on: {
                        click: function($event) {
                          return _vm.params.context.componentParent.handleOpenCopyPageModelFromChild(
                            _vm.params.data
                          )
                        }
                      }
                    },
                    [
                      _c("a-icon", { attrs: { type: "copy" } }),
                      _c("span", [_vm._v(" " + _vm._s(_vm.$t("Copy")) + " ")])
                    ],
                    1
                  ),
                  !_vm.isPrimaryPage
                    ? _c(
                        "a-menu-item",
                        {
                          key: "Move",
                          on: {
                            click: function($event) {
                              return _vm.params.context.componentParent.handleOpenMovePageModelFromChild(
                                _vm.params.data
                              )
                            }
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "drag" } }),
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("Move")) + " ")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isPinned
                    ? _c(
                        "a-menu-item",
                        { key: "PinToTop", on: { click: _vm.handlePinning } },
                        [
                          _c("a-icon", { attrs: { type: "pushpin" } }),
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("Pin to top")) + " ")
                          ])
                        ],
                        1
                      )
                    : _c(
                        "a-menu-item",
                        { key: "Unpin", on: { click: _vm.handlePinning } },
                        [
                          _c("a-icon", { attrs: { type: "pushpin" } }),
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("Unpin")) + " ")
                          ])
                        ],
                        1
                      ),
                  _c(
                    "a-menu-item",
                    { key: "5", on: { click: _vm.handlePageExport } },
                    [
                      _c("a-icon", { attrs: { type: "export" } }),
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("Export page")) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-button",
                [
                  _vm._v(_vm._s(_vm.$t("Actions"))),
                  _c("a-icon", { attrs: { type: "down" } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }