var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _vm.isPrimaryState
                ? _c("a-tag", { attrs: { color: "orange" } }, [
                    _vm._v(" " + _vm._s(_vm.$t(_vm.params.data.state)) + " ")
                  ])
                : _vm._e(),
              _vm.isPinned
                ? _c(
                    "a-tag",
                    { attrs: { color: "#2e8bce" } },
                    [_c("a-icon", { attrs: { type: "pushpin" } })],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _vm.isCatalog
                ? _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "code-list",
                          params: {
                            projectId: this.$route.params.projectId,
                            pageId: this.id.toString()
                          }
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "mb-none text-ellipsis" }, [
                        _vm._v(_vm._s(_vm.name))
                      ])
                    ]
                  )
                : _c("div", { staticClass: "mb-none text-ellipsis" }, [
                    _vm._v(_vm._s(_vm.name))
                  ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }